import React, { useState, useEffect, useMemo, useRef } from "react";
import Map, {
  Marker,
  Popup,
  ScaleControl,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DataServices from "../../Services/DataServices";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Loading from "../../Controller/LoadingController/Loading";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import UrlConfigs from "../../configs/UrlConfig";
import BinMapData from "../../Constants/BinMapData";
import BinIcon from "./BinIcon";
import Badge from "@mui/material/Badge";
import BinCategory from "../../Constants/BinLegend";

const MAP_STYLE_URL = UrlConfigs.MAP_STYLE_URL; //'https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png'; //'mapbox://styles/mapbox/streets-v11';
const MAPBOX_ACCESS_TOKEN = UrlConfigs.MAPBOX_ACCESS_TOKEN;

const initialMapViewState = {
  latitude: 1.3179623906210907,
  longitude: 103.80952185215926,
  zoom: 12,
};

const bins = "BIN";
const projectOnly = "PROJECT";
const binTypeOnly = "BINTYPE";
const bothBinProject = "BOTH";

const ViewBinInMap = ({ binType, project, setProjectDetails }) => {
  const [load, setLoad] = useState(true);
  const [viewState, setViewState] = useState(initialMapViewState);
  const [map, setMap] = useState(null);
  const [binData, setBinData] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [popUpDetails, setPopUpDetails] = useState(null);

  const CountsRecords = useRef(0);

  useEffect(() => {
    getMapView();
  }, []);

  useEffect(() => {
    //console.log("binType", binType, project, duplicateList.length > 0);
    if (duplicateList.length > 0) {
      if (project.length == 0 && binType.length == 0) {
        setBinData(duplicateList);
      } else {
        let projectArray;
        let allowFilter =
          project.length > 0 && binType.length > 0
            ? bothBinProject
            : project.length > 0
            ? projectOnly
            : binTypeOnly;
        let projectText = project.map((x, i) => {
          return x.value;
        });
        let arr = [];
        if (allowFilter == bothBinProject) {
          projectArray = duplicateList.filter((item) =>
            projectText.includes(item?.projectType)
          );
          projectArray.length > 0 &&
            projectArray.map((x, i) => {
              binType.map((y, index) => {
                if (
                  y.value.replace(/\s/g, "") == x.binType.replace(/\s/g, "")
                ) {
                  arr.push(x);
                }
              });
            });
        } else if (allowFilter == projectOnly) {
          arr = duplicateList.filter((item) =>
            projectText.includes(item?.projectType)
          );
        } else if (allowFilter == binTypeOnly) {
          duplicateList.map((x, i) => {
            binType.map((y, index) => {
              if (y.value.replace(/\s/g, "") == x.binType.replace(/\s/g, "")) {
                arr.push(x);
              }
            });
          });
        } else {
          arr = duplicateList;
        }
        GetCounts(arr);
        setBinData(arr);
      }
    }
  }, [binType, project]);

  useEffect(() => {
    if (popUpDetails) {
      setShowPopup(true);
    }
  }, [popUpDetails]);

  useEffect(() => {
    if (!showPopup) {
      setPopUpDetails(null);
    }
  }, [showPopup]);

  const getMapView = async () => {
    await DataServices.getDicView(bins)
      .then((response) => {
        let res = response?.data?.data;
        if (CountsRecords.current == 0) {
          GetCounts(res);
        }
        setBinData(res);
        setDuplicateList(res);
      })
      .catch((err) => {
        console.log("Error", err);
      });
    setLoad(false);
  };

  const GetCounts = async (data) => {
    console.log(data);
    console.log(project);
    console.log(binType);
    var dummyData = [...data];
    var countingData = BinCategory;
    countingData.map((x) => {
      x.count = 0;
    });
    if (binType.length > 0) {
      dummyData?.map((value, index) => {
        countingData.map((item) => {
          project.map((projects) => {
            if (projects.label == value.projectType) {
              if (
                item.value.replace(/\s/g, "") ==
                value.binType.replace(/\s/g, "")
              ) {
                item.count = item.count + 1;
              }
            }
          });
        });
      });
    }

    CountsRecords.current = 1;
    setProjectDetails(countingData);
  };

  const viewPopUp = () => {
    if (popUpDetails) {
      return (
        <Popup
          longitude={parseFloat(popUpDetails.longitude)}
          latitude={parseFloat(popUpDetails.latitude)}
          anchor="center"
          closeOnClick={false}
          onClose={() => setShowPopup(false)}>
          <Card sx={{ width: `320px` }}>
            {popUpDetails.image && (
              <CardMedia sx={{ height: 200 }} image={popUpDetails.image} />
            )}

            <CardContent>
              <List
                sx={{
                  overflowY: "auto",
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  height: "100%",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                    outline: "1px solid slategrey",
                  },
                }}>
                <ListItem>
                  {popUpDetails.deviceId && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`DeviceId :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          sx={{ WebkitLineClamp: 2, wordWrap: "break-word" }}>
                          {popUpDetails.deviceId}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem disableGutters>
                  {popUpDetails.projectType && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`Project Type :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.projectType}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem>
                  {popUpDetails.binType && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`Bin Type :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.binType}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem>
                  {popUpDetails.address && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`Address :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Popup>
      );
    }
  };

  const getBinPopUp = (x) => {
    setPopUpDetails(x);
  };

  const getIconSize = (zoom) => {
    let zoomData = zoom < 12 ? zoom : 12;
    return Math.pow(9 - zoomData, 2) * 3; // Adjust the size as needed
  };

  const SeverityBasedBin = (x) => {
    //let imageData = x.level > 80 ? CriticalSvg : x.level > 50 ? ModerateSvg : CleanSvg
    return (
      <BinIcon
        width={getIconSize(viewState.zoom)}
        height={getIconSize(viewState.zoom)}
        fill={x.color ? x.color : "#000"}
      />
    );
  };

  {
    /* <img src={imageData} 
      style={{
      width: `${getIconSize(viewState.zoom)}px`,
      height: `${getIconSize(viewState.zoom)}px`,
      }}/>  CustomBinSvg*/
  }

  //once api is ready change to binData in 166line
  const getBinDetails = () => {
    return (
      <>
        {binData.length > 0 &&
          binData.map((x, i) => (
            <Marker
              key={`${i}-marker-${i}`}
              latitude={x.latitude}
              longitude={x.longitude}
              onClick={() => getBinPopUp(x)}>
              {SeverityBasedBin(x)}
            </Marker>
          ))}
      </>
    );
  };

  const flyTo = (coordinates) => {
    console.log([coordinates.lngLat.lng, coordinates.lngLat.lat]);
    map.flyTo({
      center: [Number(coordinates.lngLat.lng), Number(coordinates.lngLat.lat)],
    });
  };

  return (
    <Map
      initialViewState={viewState}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      onMove={(evt) => setViewState(evt?.viewState)}
      style={{ width: `100%`, height: `75vh` }}
      mapStyle={MAP_STYLE_URL}
      onLoad={(event) => setMap(event.target)}
      onClick={flyTo}>
      {getBinDetails()}
      {showPopup && viewPopUp()}
      <Loading loading={load} type={`glass`} backDrop={true} />
      <NavigationControl anchor="bottom-right" />
      <FullscreenControl />
      <GeolocateControl />
      <ScaleControl />
    </Map>
  );
};

export default ViewBinInMap;
