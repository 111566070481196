import React, { useState, useCallback, Fragment, useEffect, lazy, Suspense } from "react";
import { Grid, Card, CardContent, Typography, TextField } from "@mui/material"
import Header from "../../Controller/HeaderController/Header";
import ViewDicInMap from "./ViewDicInMap";
import DataServices from "../../Services/DataServices";
import { useTheme } from '@mui/material/styles';
import dicresponse from "./dicstaticresponse";


const dlc = "DLC"

const DicComponent=()=>{
    const theme = useTheme();
    const [load, setLoad] = useState(true);
    const [dicData, setDicData] = useState([])
    const [cleanData, setCleanData] = useState(0)
    const [moderateData, setModerateData] = useState(0)
    const [criticalData, setCriticalData] = useState(0)

    useEffect(() => {
        getMapView();
    }, []);

    useEffect(() => {
        let critical = dicData.filter(sc => sc.level>80);
        let moderate = dicData.filter(sc => sc.level>50);
        let clean = dicData.filter(sc => sc.level<50);
        setCleanData(clean.length)
        setModerateData(moderate.length)
        setCriticalData(critical.length)
    }, [dicData]);

    const getMapView=async()=>{
        setDicData(dicresponse);
        /*await DataServices.getDicView(dlc).then((response) => {
            let res =  response?.data?.data
            console.log('ress-----',JSON.stringify(res));
            setDicData(res)
          }).catch((err)=>{
            console.log("Error", err)
          })*/
          setLoad(false)
    }

    const getDetails=(data, color, title)=>{
        return(
            <Card style={{backgroundColor: color, margin: 3}}>
                <Typography variant="h5" style={{ textAlign:`center`}}>
                    {data}
                </Typography>
                <p style={{ textAlign:`center`, margin: '6px'}}>{title}</p>
            </Card>   
        )
    }

    return(
        <Grid container spacing={0} style={{marginTop: `-20px`}}>
            <Grid item xs={12} md={12} sm={12}>
                <Header title={`Dic sensor`} />
            </Grid> 
            <Grid item xs={12} md={12} sm={12} style={{marginTop: `-5px`}}>
                <Grid container spacing={2}>
                    <Grid item xs={4} md={4} sm={4} style={{padding: 20}}>
                        {getDetails(cleanData, theme.palette.success.dark, 'Clean')}
                    </Grid>
                    <Grid item xs={4} md={4} sm={4} style={{padding: 20}}>
                        {getDetails(moderateData, theme.palette.warning.dark, 'Moderate')}
                    </Grid>
                    <Grid item xs={4} md={4} sm={4} style={{padding: 20}}>
                        {getDetails(criticalData, theme.palette.error.dark,'Critical')}
                    </Grid>
                </Grid>
            </Grid> 
            <Grid item xs={12} md={12} sm={12} style={{marginTop: `-10px`}}>
               <ViewDicInMap loading={load} dicData={dicData}/>
            </Grid>
        </Grid>   
    )
}

export default DicComponent;

