const dicresponse = [
    {
      "id": "651dba6fa4d47d41658518bb",
      "coordinates": [
        "1.3083798",
        "103.7930697"
      ],
      "deviceType": "DLC",
      "binType": "Standard Green Ashtray Bin",
      "projectType": "IPC-CS",
      "latitude": "1.3083798",
      "longitude": "103.7930697",
      "address": "Blk 10a, Singapore",
      "deviceId": "E2803821200064260744FA0E",
      "deviceStatus": "ACTIVE",
      "image": "",
      "createdDateTime": 1696447087.563,
      "modifiedDateTime": 1696447110.919,
      "formattedCreatedDateTime": "2023-10-5 03:18:07",
      "formattedModifiedDateTime": "2023-10-5 03:18:30"
    },
    {
      "id": "651be97b366329145bd39f28",
      "coordinates": [
        "1.3279934",
        "103.8695941"
      ],
      "deviceType": "DLC",
      "latitude": "1.3279934",
      "longitude": "103.8695941",
      "address": "MacPherson Rd, Singapore",
      "deviceId": "2023070011",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070011.jpg",
      "level": "60.2",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696328059.035,
      "modifiedDateTime": 1701357451.459,
      "formattedCreatedDateTime": "2023-10-3 18:14:19",
      "formattedModifiedDateTime": "2023-11-30 23:17:31"
    },
    {
      "id": "651be536366329145bd39f27",
      "coordinates": [
        "1.3600248",
        "103.8840657"
      ],
      "deviceType": "DLC",
      "latitude": "1.3600248",
      "longitude": "103.8840657",
      "address": "900 Upper Serangoon Rd, Kovan, Singapore 534799",
      "deviceId": "202307083",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070083.jpeg",
      "level": "47.11",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1696326966.837,
      "modifiedDateTime": 1696326966.837,
      "formattedCreatedDateTime": "2023-10-3 17:56:06",
      "formattedModifiedDateTime": "2023-10-3 17:56:06"
    },
    {
      "id": "651bd41bcf71600004ce40c5",
      "coordinates": [
        "1.3099513",
        "103.835955"
      ],
      "deviceType": "DLC",
      "latitude": "1.3099513",
      "longitude": "103.835955",
      "address": "79 Cairnhill Rd, #15-01, Singapore 229681",
      "deviceId": "2023070049",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070049.jpg",
      "level": "83.03",
      "unit": "cm",
      "status": "critical",
      "createdDateTime": 1696322587.16,
      "modifiedDateTime": 1696322587.16,
      "formattedCreatedDateTime": "2023-10-3 16:43:07",
      "formattedModifiedDateTime": "2023-10-3 16:43:07"
    },
    {
      "id": "651bd3f9cf71600004ce40c4",
      "coordinates": [
        "1.3284263",
        "103.8686543"
      ],
      "deviceType": "DLC",
      "latitude": "1.3284263",
      "longitude": "103.8686543",
      "address": "Jln Toa Payoh, Singapore",
      "deviceId": "2023070016",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070016.jpg",
      "level": "50.86",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696322553.098,
      "modifiedDateTime": 1701343683.816,
      "formattedCreatedDateTime": "2023-10-3 16:42:33",
      "formattedModifiedDateTime": "2023-11-30 19:28:03"
    },
    {
      "id": "651bd029cf71600004ce40c3",
      "coordinates": [
        "1.3125512",
        "103.8633492"
      ],
      "deviceType": "DLC",
      "latitude": "1.3125512",
      "longitude": "103.8633492",
      "address": "2 Kallang Ave, Singapore",
      "deviceId": "2023070005",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070005.jpg",
      "level": "89.34",
      "unit": "cm",
      "status": "critical",
      "createdDateTime": 1696321577.8,
      "modifiedDateTime": 1701045428.591,
      "formattedCreatedDateTime": "2023-10-3 16:26:17",
      "formattedModifiedDateTime": "2023-11-27 08:37:08"
    },
    {
      "id": "651bca5dcf71600004ce40c2",
      "coordinates": [
        "1.3000114",
        "103.836306"
      ],
      "deviceType": "DLC",
      "latitude": "1.3000114",
      "longitude": "103.836306",
      "address": "127 Devonshire Rd, Singapore 239885",
      "deviceId": "2023070052",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070052.jpg",
      "level": "59.57",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696320093.659,
      "modifiedDateTime": 1696839276.766,
      "formattedCreatedDateTime": "2023-10-3 16:01:33",
      "formattedModifiedDateTime": "2023-10-9 16:14:36"
    },
    {
      "id": "651bc7a7cf71600004ce40c1",
      "coordinates": [
        "1.331933",
        "103.8104785"
      ],
      "deviceType": "DLC",
      "latitude": "1.331933",
      "longitude": "103.8104785",
      "address": "138B Watten Estate Rd, Singapore 287621",
      "deviceId": "2023070017",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070017.jpg",
      "level": "58.69",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696319399.818,
      "modifiedDateTime": 1701771493.563,
      "formattedCreatedDateTime": "2023-10-3 15:49:59",
      "formattedModifiedDateTime": "2023-12-5 18:18:13"
    },
    {
      "id": "651bc0eacf71600004ce40c0",
      "coordinates": [
        "1.328377",
        "103.8692434"
      ],
      "deviceType": "DLC",
      "latitude": "1.328377",
      "longitude": "103.8692434",
      "address": "39 MacPherson Rd, Singapore",
      "deviceId": "2023070029",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070029.jpg",
      "level": "72.14",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696317674.124,
      "modifiedDateTime": 1699160934.817,
      "formattedCreatedDateTime": "2023-10-3 15:21:14",
      "formattedModifiedDateTime": "2023-11-5 13:08:54"
    },
    {
      "id": "651bb5becf71600004ce40bf",
      "coordinates": [
        "1.361288",
        "103.891183"
      ],
      "deviceType": "DLC",
      "latitude": "1.361288",
      "longitude": "103.891183",
      "address": "92 Tampines Rd, Singapore 535117",
      "deviceId": "2023070073",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070073.jpg",
      "level": "30.63",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1696314814.317,
      "modifiedDateTime": 1701420707.842,
      "formattedCreatedDateTime": "2023-10-3 14:33:34",
      "formattedModifiedDateTime": "2023-12-1 16:51:47"
    },
    {
      "id": "651bb25ccf71600004ce40be",
      "coordinates": [
        "1.3708625",
        "103.8963047"
      ],
      "deviceType": "DLC",
      "latitude": "1.3708625",
      "longitude": "103.8963047",
      "address": "1192 Upper Serangoon Rd, Singapore 534787",
      "deviceId": "202307084",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070084.jpeg",
      "level": "36.57",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1696313948.023,
      "modifiedDateTime": 1696313948.023,
      "formattedCreatedDateTime": "2023-10-3 14:19:08",
      "formattedModifiedDateTime": "2023-10-3 14:19:08"
    },
    {
      "id": "651bb23bcf71600004ce40bd",
      "coordinates": [
        "1.3152754",
        "103.8730817"
      ],
      "deviceType": "DLC",
      "latitude": "1.3152754",
      "longitude": "103.8730817",
      "address": "4 Upper Boon Keng Rd, Singapore 380004",
      "deviceId": "2023070007",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070007.jpg",
      "level": "61.31",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696313915.456,
      "modifiedDateTime": 1699506886.334,
      "formattedCreatedDateTime": "2023-10-3 14:18:35",
      "formattedModifiedDateTime": "2023-11-9 13:14:46"
    },
    {
      "id": "651bab13cf71600004ce40bc",
      "coordinates": [
        "1.328782",
        "103.8680192"
      ],
      "deviceType": "DLC",
      "latitude": "1.328782",
      "longitude": "103.8680192",
      "address": "12 Woodsville Cl, Singapore 357768",
      "deviceId": "2023070021",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070021.jpg",
      "level": "65.29",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1696312083.301,
      "modifiedDateTime": 1701758120.555,
      "formattedCreatedDateTime": "2023-10-3 13:48:03",
      "formattedModifiedDateTime": "2023-12-5 14:35:20"
    },
    {
      "id": "651ba3f0cf71600004ce40bb",
      "coordinates": [
        "1.3366572",
        "103.8084586"
      ],
      "deviceType": "DLC",
      "latitude": "1.3366572",
      "longitude": "103.8084586",
      "address": "181 Hillcrest Rd, Singapore 289064",
      "deviceId": "2023070014",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070014.jpg",
      "level": "37.34",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1696310256.142,
      "modifiedDateTime": 1699514536.757,
      "formattedCreatedDateTime": "2023-10-3 13:17:36",
      "formattedModifiedDateTime": "2023-11-9 15:22:16"
    },
    {
      "id": "651b935acf71600004ce40b9",
      "coordinates": [
        "1.3170337",
        "103.809762"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3170337",
      "longitude": "103.809762",
      "address": "8 Woollerton Dr, Singapore 257558",
      "deviceId": "2023070055",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070055.jpg",
      "level": "27.11",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1696306010.012,
      "modifiedDateTime": 1696843652.641,
      "formattedCreatedDateTime": "2023-10-3 12:06:50",
      "formattedModifiedDateTime": "2023-10-9 17:27:32"
    },
    {
      "id": "651bf98209eea100fccd60bd",
      "coordinates": [
        "1.3732977",
        "103.944582"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3732977",
      "longitude": "103.944582",
      "address": "Pasir Ris Dr 1, Singapore",
      "deviceId": "2023070092",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070092.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694156234.291,
      "modifiedDateTime": 1694156234.291,
      "formattedCreatedDateTime": "2023-9-8 14:57:14",
      "formattedModifiedDateTime": "2023-9-8 14:57:14"
    },
    {
      "id": "651bf96809eea100fccd60bc",
      "coordinates": [
        "1.3757545",
        "103.9401911"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3757545",
      "longitude": "103.9401911",
      "address": "640 Pasir Ris Dr 1, Block 640, Singapore 510640",
      "deviceId": "2023070088",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070088.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694155756.119,
      "modifiedDateTime": 1694155756.119,
      "formattedCreatedDateTime": "2023-9-8 14:49:16",
      "formattedModifiedDateTime": "2023-9-8 14:49:16"
    },
    {
      "id": "651bf95009eea100fccd60bb",
      "coordinates": [
        "1.3799563",
        "103.9344366"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3799563",
      "longitude": "103.9344366",
      "address": "Pasir Ris Dr 1, Singapore",
      "deviceId": "2023070091",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070091.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694155174.322,
      "modifiedDateTime": 1694155174.322,
      "formattedCreatedDateTime": "2023-9-8 14:39:34",
      "formattedModifiedDateTime": "2023-9-8 14:39:34"
    },
    {
      "id": "651bf93809eea100fccd60ba",
      "coordinates": [
        "1.3360786",
        "103.9187408"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3360786",
      "longitude": "103.9187408",
      "address": "706 Bedok North Rd, Singapore 470706",
      "deviceId": "2023070089",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070089.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694154103.262,
      "modifiedDateTime": 1694154103.262,
      "formattedCreatedDateTime": "2023-9-8 14:21:43",
      "formattedModifiedDateTime": "2023-9-8 14:21:43"
    },
    {
      "id": "651bf92309eea100fccd60b9",
      "coordinates": [
        "1.3408734",
        "103.9188062"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3408734",
      "longitude": "103.9188062",
      "address": "900 Bedok North Rd, Singapore 479994",
      "deviceId": "2023070085",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070085.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694153048.834,
      "modifiedDateTime": 1694153048.834,
      "formattedCreatedDateTime": "2023-9-8 14:04:08",
      "formattedModifiedDateTime": "2023-9-8 14:04:08"
    },
    {
      "id": "651bf90009eea100fccd60b8",
      "coordinates": [
        "1.3481354",
        "103.9259203"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3481354",
      "longitude": "103.9259203",
      "address": "81 Tampines Ave 10, Singapore 528685",
      "deviceId": "2023070082",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070082.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694152091.023,
      "modifiedDateTime": 1694152091.023,
      "formattedCreatedDateTime": "2023-9-8 13:48:11",
      "formattedModifiedDateTime": "2023-9-8 13:48:11"
    },
    {
      "id": "651bf8e509eea100fccd60b7",
      "coordinates": [
        "1.3315702",
        "103.9102995"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3315702",
      "longitude": "103.9102995",
      "address": "113-110 Bedok Reservoir Rd, Singapore",
      "deviceId": "2023070087",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070087.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694150983.334,
      "modifiedDateTime": 1694150983.334,
      "formattedCreatedDateTime": "2023-9-8 13:29:43",
      "formattedModifiedDateTime": "2023-9-8 13:29:43"
    },
    {
      "id": "651bf8ca09eea100fccd60b6",
      "coordinates": [
        "1.3346972",
        "103.9042981"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3346972",
      "longitude": "103.9042981",
      "address": "60 Kaki Bukit Pl, Singapore 410659",
      "deviceId": "2023070094",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070094.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694144789.46,
      "modifiedDateTime": 1694144789.46,
      "formattedCreatedDateTime": "2023-9-8 11:46:29",
      "formattedModifiedDateTime": "2023-9-8 11:46:29"
    },
    {
      "id": "651bf8b309eea100fccd60b5",
      "coordinates": [
        "1.3349863",
        "103.8988065"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3349863",
      "longitude": "103.8988065",
      "address": "Eunos Link, Singapore",
      "deviceId": "2023070090",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070090.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694143334.755,
      "modifiedDateTime": 1694143334.755,
      "formattedCreatedDateTime": "2023-9-8 11:22:14",
      "formattedModifiedDateTime": "2023-9-8 11:22:14"
    },
    {
      "id": "651bf89b09eea100fccd60b4",
      "coordinates": [
        "1.3499806",
        "103.8884117"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3499806",
      "longitude": "103.8884117",
      "address": "174A Hougang Ave 1, Singapore 531174",
      "deviceId": "2023070097",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070097.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694142327.886,
      "modifiedDateTime": 1694142327.886,
      "formattedCreatedDateTime": "2023-9-8 11:05:27",
      "formattedModifiedDateTime": "2023-9-8 11:05:27"
    },
    {
      "id": "651bf88209eea100fccd60b3",
      "coordinates": [
        "1.3600248",
        "103.8840657"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3600248",
      "longitude": "103.8840657",
      "address": "900 Upper Serangoon Rd, Kovan, Singapore 534799",
      "deviceId": "2023070083",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070083.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694141457.266,
      "modifiedDateTime": 1694141457.266,
      "formattedCreatedDateTime": "2023-9-8 10:50:57",
      "formattedModifiedDateTime": "2023-9-8 10:50:57"
    },
    {
      "id": "651bf86b09eea100fccd60b2",
      "coordinates": [
        "1.3640949",
        "103.8895644"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3640949",
      "longitude": "103.8895644",
      "address": "BLK 370 HOUGANG STREET 31#04-11 530370, Singapore 530370",
      "deviceId": "2023070086",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070086.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694140987.94,
      "modifiedDateTime": 1694140987.94,
      "formattedCreatedDateTime": "2023-9-8 10:43:07",
      "formattedModifiedDateTime": "2023-9-8 10:43:07"
    },
    {
      "id": "651bf84c09eea100fccd60b1",
      "coordinates": [
        "1.3777676",
        "103.9033755"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3777676",
      "longitude": "103.9033755",
      "address": "476 Upper Serangoon View, Singapore 531476",
      "deviceId": "2023070096",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070096.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694138882.826,
      "modifiedDateTime": 1694138882.826,
      "formattedCreatedDateTime": "2023-9-8 10:08:02",
      "formattedModifiedDateTime": "2023-9-8 10:08:02"
    },
    {
      "id": "651bf82d09eea100fccd60b0",
      "coordinates": [
        "1.3712169",
        "103.8880596"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3712169",
      "longitude": "103.8880596",
      "address": "93 Hougang Ave 4, Singapore",
      "deviceId": "2023070093",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070093.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694058028.565,
      "modifiedDateTime": 1694058028.565,
      "formattedCreatedDateTime": "2023-9-7 11:40:28",
      "formattedModifiedDateTime": "2023-9-7 11:40:28"
    },
    {
      "id": "651bf80b09eea100fccd60af",
      "coordinates": [
        "1.3768483",
        "103.8964494"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3768483",
      "longitude": "103.8964494",
      "address": "457 Hougang Ave 10, Singapore 530457",
      "deviceId": "2023070095",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070095.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694057311.423,
      "modifiedDateTime": 1694057311.423,
      "formattedCreatedDateTime": "2023-9-7 11:28:31",
      "formattedModifiedDateTime": "2023-9-7 11:28:31"
    },
    {
      "id": "651bf7eb09eea100fccd60ae",
      "coordinates": [
        "1.376145",
        "103.8926677"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.376145",
      "longitude": "103.8926677",
      "address": "432 Hougang Ave 6, Block 432, Singapore 530432",
      "deviceId": "2023070098",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070098.jpeg",
      "level": "",
      "unit": "",
      "status": "",
      "createdDateTime": 1694056061.73,
      "modifiedDateTime": 1694056061.73,
      "formattedCreatedDateTime": "2023-9-7 11:07:41",
      "formattedModifiedDateTime": "2023-9-7 11:07:41"
    },
    {
      "id": "651bfbed09eea100fccd60c5",
      "coordinates": [
        "1.3172",
        "103.8098567"
      ],
      "deviceType": "FloodSensor",
      "projectType": "",
      "latitude": "1.3172",
      "longitude": "103.8098567",
      "address": "10 Woollerton Dr, Singapore 257559",
      "deviceId": "2023070099",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070099.jpg",
      "level": "32.57",
      "unit": "cm",
      "status": "FloodWarning",
      "createdDateTime": 1692399885.697,
      "modifiedDateTime": 1701658400.256,
      "formattedCreatedDateTime": "2023-8-19 07:04:45",
      "formattedModifiedDateTime": "2023-12-4 10:53:20"
    },
    {
      "id": "651bfc3d09eea100fccd60c7",
      "coordinates": [
        "1.3172216",
        "103.8098309"
      ],
      "deviceType": "FloodSensor",
      "projectType": "",
      "latitude": "1.3172216",
      "longitude": "103.8098309",
      "address": "10 Woollerton Dr, Singapore 257559",
      "deviceId": "2023070100",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070100.jpg",
      "level": "29.06",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1692399885.697,
      "modifiedDateTime": 1701325376.517,
      "formattedCreatedDateTime": "2023-8-19 07:04:45",
      "formattedModifiedDateTime": "2023-11-30 14:22:56"
    },
    {
      "id": "651bf52b09eea100fccd609d",
      "coordinates": [
        "1.3279936",
        "103.8695905"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3279936",
      "longitude": "103.8695905",
      "address": "MacPherson Rd, Singapore",
      "deviceId": "2023070035",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070035.jpg",
      "level": "57.94",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691395228.493,
      "modifiedDateTime": 1701799109.18,
      "formattedCreatedDateTime": "2023-8-7 16:00:28",
      "formattedModifiedDateTime": "2023-12-6 01:58:29"
    },
    {
      "id": "651bf4fa09eea100fccd609c",
      "coordinates": [
        "1.355287",
        "103.8586779"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.355287",
      "longitude": "103.8586779",
      "address": "1801 Ang Mo Kio Ave 1, Singapore 569979",
      "deviceId": "2023070074",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070074.jpg",
      "level": "79.77",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691078073.522,
      "modifiedDateTime": 1698630517.71,
      "formattedCreatedDateTime": "2023-8-3 23:54:33",
      "formattedModifiedDateTime": "2023-10-30 09:48:37"
    },
    {
      "id": "651bf4dc09eea100fccd609b",
      "coordinates": [
        "1.3798788",
        "103.8925282"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3798788",
      "longitude": "103.8925282",
      "address": "Buangkok Dr, Singapore",
      "deviceId": "2023070064",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070064.jpg",
      "level": "56.8",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691077109.672,
      "modifiedDateTime": 1701546054.799,
      "formattedCreatedDateTime": "2023-8-3 23:38:29",
      "formattedModifiedDateTime": "2023-12-3 03:40:54"
    },
    {
      "id": "651bf41509eea100fccd6096",
      "coordinates": [
        "1.3615389",
        "103.8922042"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3615389",
      "longitude": "103.8922042",
      "address": "161A Tampines Rd, Singapore 535161",
      "deviceId": "2023070079",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070079.jpg",
      "level": "40.8",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691076196.603,
      "modifiedDateTime": 1701780039.268,
      "formattedCreatedDateTime": "2023-8-3 23:23:16",
      "formattedModifiedDateTime": "2023-12-5 20:40:39"
    },
    {
      "id": "651bf3f209eea100fccd6095",
      "coordinates": [
        "1.351062",
        "103.8619412"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.351062",
      "longitude": "103.8619412",
      "address": "New Tech Pk, Singapore",
      "deviceId": "2023070081",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070081.jpg",
      "level": "56.09",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691076146.963,
      "modifiedDateTime": 1701296838.431,
      "formattedCreatedDateTime": "2023-8-3 23:22:26",
      "formattedModifiedDateTime": "2023-11-30 06:27:18"
    },
    {
      "id": "651bf2f209eea100fccd608f",
      "coordinates": [
        "1.3798863",
        "103.8987091"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3798863",
      "longitude": "103.8987091",
      "address": "1 Rivervale Link, Singapore 545118",
      "deviceId": "2023070076",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070076.jpg",
      "level": "41.43",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691064550.755,
      "modifiedDateTime": 1701799677.477,
      "formattedCreatedDateTime": "2023-8-3 20:09:10",
      "formattedModifiedDateTime": "2023-12-6 02:07:57"
    },
    {
      "id": "651bf27809eea100fccd608d",
      "coordinates": [
        "1.355026",
        "103.8671404"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.355026",
      "longitude": "103.8671404",
      "address": "307 Serangoon Ave 2, Block 307, Singapore 550307",
      "deviceId": "2023070080",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070080.jpg",
      "level": "34.26",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691057835.163,
      "modifiedDateTime": 1700995471.568,
      "formattedCreatedDateTime": "2023-8-3 18:17:15",
      "formattedModifiedDateTime": "2023-11-26 18:44:31"
    },
    {
      "id": "651bf21809eea100fccd608b",
      "coordinates": [
        "1.3471907",
        "103.8598173"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3471907",
      "longitude": "103.8598173",
      "address": "33 Dunsfold Dr, Singapore 359391",
      "deviceId": "2023070078",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070078.jpg",
      "level": "81.31",
      "unit": "cm",
      "status": "critical",
      "createdDateTime": 1691055452.484,
      "modifiedDateTime": 1701168664.071,
      "formattedCreatedDateTime": "2023-8-3 17:37:32",
      "formattedModifiedDateTime": "2023-11-28 18:51:04"
    },
    {
      "id": "651bf1f909eea100fccd608a",
      "coordinates": [
        "1.3610134",
        "103.8875772"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3610134",
      "longitude": "103.8875772",
      "address": "69 Tampines Rd, Singapore",
      "deviceId": "2023070062",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070062.jpg",
      "level": "45.26",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691053299.837,
      "modifiedDateTime": 1701795586.607,
      "formattedCreatedDateTime": "2023-8-3 17:01:39",
      "formattedModifiedDateTime": "2023-12-6 00:59:46"
    },
    {
      "id": "651bf1df09eea100fccd6089",
      "coordinates": [
        "1.3613081",
        "103.8911428"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3613081",
      "longitude": "103.8911428",
      "address": "92 Tampines Rd, Singapore 535117",
      "deviceId": "2023070072",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070072.jpg",
      "level": "32.09",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691049492.841,
      "modifiedDateTime": 1695034206.085,
      "formattedCreatedDateTime": "2023-8-3 15:58:12",
      "formattedModifiedDateTime": "2023-9-18 18:50:06"
    },
    {
      "id": "651bf1c309eea100fccd6088",
      "coordinates": [
        "1.3538336",
        "103.8594886"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3538336",
      "longitude": "103.8594886",
      "address": "8 Mei Hwan Dr, Singapore 568348",
      "deviceId": "2023070065",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070065.jpg",
      "level": "45.77",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691048352.636,
      "modifiedDateTime": 1695047898.126,
      "formattedCreatedDateTime": "2023-8-3 15:39:12",
      "formattedModifiedDateTime": "2023-9-18 22:38:18"
    },
    {
      "id": "651bf1a609eea100fccd6087",
      "coordinates": [
        "1.3541259",
        "103.8617382"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3541259",
      "longitude": "103.8617382",
      "address": "109 Mei Hwan Dr, Singapore 568411",
      "deviceId": "2023070070",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070070.jpg",
      "level": "64.46",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691047170.136,
      "modifiedDateTime": 1700684396.717,
      "formattedCreatedDateTime": "2023-8-3 15:19:30",
      "formattedModifiedDateTime": "2023-11-23 04:19:56"
    },
    {
      "id": "651bf17709eea100fccd6085",
      "coordinates": [
        "1.3797516",
        "103.8977637"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3797516",
      "longitude": "103.8977637",
      "address": "3 Rivervale Link, Singapore 545119",
      "deviceId": "2023070071",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070071.jpg",
      "level": "48.83",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691047044.595,
      "modifiedDateTime": 1701646675.853,
      "formattedCreatedDateTime": "2023-8-3 15:17:24",
      "formattedModifiedDateTime": "2023-12-4 07:37:55"
    },
    {
      "id": "651bf15609eea100fccd6084",
      "coordinates": [
        "1.3540064",
        "103.8653112"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3540064",
      "longitude": "103.8653112",
      "address": "309 Serangoon Ave 2, Block 309, Singapore 550309",
      "deviceId": "2023070067",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070067.jpg",
      "level": "64.43",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691046112.666,
      "modifiedDateTime": 1701761960.816,
      "formattedCreatedDateTime": "2023-8-3 15:01:52",
      "formattedModifiedDateTime": "2023-12-5 15:39:20"
    },
    {
      "id": "651bf13209eea100fccd6083",
      "coordinates": [
        "1.361252",
        "103.8918103"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.361252",
      "longitude": "103.8918103",
      "address": "114 Tampines Rd, Singapore 535135",
      "deviceId": "2023070063",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070063.jpg",
      "level": "36.63",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691045560.63,
      "modifiedDateTime": 1701826304.611,
      "formattedCreatedDateTime": "2023-8-3 14:52:40",
      "formattedModifiedDateTime": "2023-12-6 09:31:44"
    },
    {
      "id": "651bf10d09eea100fccd6082",
      "coordinates": [
        "1.3277315",
        "103.8673089"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3277315",
      "longitude": "103.8673089",
      "address": "10 Opal Cres, Singapore 328405",
      "deviceId": "2023070012",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070012.jpg",
      "level": "73.34",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691045043.659,
      "modifiedDateTime": 1700847481.208,
      "formattedCreatedDateTime": "2023-8-3 14:44:03",
      "formattedModifiedDateTime": "2023-11-25 01:38:01"
    },
    {
      "id": "651bf0f309eea100fccd6081",
      "coordinates": [
        "1.3800047",
        "103.9029011"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3800047",
      "longitude": "103.9029011",
      "address": "12 Upper Serangoon Cres, Singapore",
      "deviceId": "2023070066",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070066.jpg",
      "level": "51.49",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691043283.5,
      "modifiedDateTime": 1697950145.361,
      "formattedCreatedDateTime": "2023-8-3 14:14:43",
      "formattedModifiedDateTime": "2023-10-22 12:49:05"
    },
    {
      "id": "651bf07709eea100fccd607b",
      "coordinates": [
        "1.3540725",
        "103.8623419"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3540725",
      "longitude": "103.8623419",
      "address": "127 Mei Hwan Dr, Singapore 568420",
      "deviceId": "2023070069",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070069.jpg",
      "level": "75.97",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691041167.181,
      "modifiedDateTime": 1701834551.95,
      "formattedCreatedDateTime": "2023-8-3 13:39:27",
      "formattedModifiedDateTime": "2023-12-6 11:49:11"
    },
    {
      "id": "651bf05b09eea100fccd607a",
      "coordinates": [
        "1.382666",
        "103.8892063"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.382666",
      "longitude": "103.8892063",
      "address": "Buangkok Dr, Sengkang E Rd, Singapore",
      "deviceId": "2023070077",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070077.jpg",
      "level": "53.86",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1691039872.575,
      "modifiedDateTime": 1697941995.88,
      "formattedCreatedDateTime": "2023-8-3 13:17:52",
      "formattedModifiedDateTime": "2023-10-22 10:33:15"
    },
    {
      "id": "651bf04009eea100fccd6079",
      "coordinates": [
        "1.3071642",
        "103.8821792"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3071642",
      "longitude": "103.8821792",
      "address": "Nicoll Hwy, Singapore",
      "deviceId": "2023070040",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070040.jpg",
      "level": "40.91",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691037574.155,
      "modifiedDateTime": 1699478043.351,
      "formattedCreatedDateTime": "2023-8-3 12:39:34",
      "formattedModifiedDateTime": "2023-11-9 05:14:03"
    },
    {
      "id": "651bf02109eea100fccd6078",
      "coordinates": [
        "1.3615447",
        "103.8911695"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3615447",
      "longitude": "103.8911695",
      "address": "1210E Tampines Rd, Singapore 508119",
      "deviceId": "2023070068",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070068.jpg",
      "level": "39.06",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1691034207.135,
      "modifiedDateTime": 1701488836.116,
      "formattedCreatedDateTime": "2023-8-3 11:43:27",
      "formattedModifiedDateTime": "2023-12-2 11:47:16"
    },
    {
      "id": "651bf00209eea100fccd6077",
      "coordinates": [
        "1.2963052",
        "103.856089"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2963052",
      "longitude": "103.856089",
      "address": "47 Beach Rd, Singapore 189683",
      "deviceId": "2023070038",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070038.jpg",
      "level": "54.69",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690989836.753,
      "modifiedDateTime": 1700296860.044,
      "formattedCreatedDateTime": "2023-8-2 23:23:56",
      "formattedModifiedDateTime": "2023-11-18 16:41:00"
    },
    {
      "id": "651befe209eea100fccd6076",
      "coordinates": [
        "1.2940869",
        "103.8555397"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2940869",
      "longitude": "103.8555397",
      "address": "90 Bras Basah Rd, Esplanade, Singapore 189562",
      "deviceId": "2023070001",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070001.jpg",
      "level": "58.74",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690989836.75,
      "modifiedDateTime": 1696525190.584,
      "formattedCreatedDateTime": "2023-8-2 23:23:56",
      "formattedModifiedDateTime": "2023-10-6 00:59:50"
    },
    {
      "id": "651bef8109eea100fccd6073",
      "coordinates": [
        "1.2949336",
        "103.8555909"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2949336",
      "longitude": "103.8555909",
      "address": "8 Beach Rd, Singapore 189702",
      "deviceId": "2023070037",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070037.jpg",
      "level": "45.49",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690986132.035,
      "modifiedDateTime": 1701758918.383,
      "formattedCreatedDateTime": "2023-8-2 22:22:12",
      "formattedModifiedDateTime": "2023-12-5 14:48:38"
    },
    {
      "id": "651bef5c09eea100fccd6072",
      "coordinates": [
        "1.2976552",
        "103.8590036"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2976552",
      "longitude": "103.8590036",
      "address": "80 Nicoll Hwy, Singapore 188836",
      "deviceId": "2023070034",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070034.jpg",
      "level": "89.34",
      "unit": "cm",
      "status": "critical",
      "createdDateTime": 1690978401.508,
      "modifiedDateTime": 1701766880.963,
      "formattedCreatedDateTime": "2023-8-2 20:13:21",
      "formattedModifiedDateTime": "2023-12-5 17:01:20"
    },
    {
      "id": "651bef3f09eea100fccd6071",
      "coordinates": [
        "1.2996746",
        "103.8602134"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2996746",
      "longitude": "103.8602134",
      "address": "7500B Beach Rd, Singapore 199592",
      "deviceId": "2023070004",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070004.jpg",
      "level": "72.63",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690971273.404,
      "modifiedDateTime": 1700940226.589,
      "formattedCreatedDateTime": "2023-8-2 18:14:33",
      "formattedModifiedDateTime": "2023-11-26 03:23:46"
    },
    {
      "id": "651beef409eea100fccd606e",
      "coordinates": [
        "1.3795899",
        "103.8947289"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3795899",
      "longitude": "103.8947289",
      "address": "447 Hougang Ave 10, Singapore 530447",
      "deviceId": "2023070075",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070075.jpg",
      "level": "53.6",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690966924.129,
      "modifiedDateTime": 1701640495.996,
      "formattedCreatedDateTime": "2023-8-2 17:02:04",
      "formattedModifiedDateTime": "2023-12-4 05:54:55"
    },
    {
      "id": "651bee9409eea100fccd606c",
      "coordinates": [
        "1.2939512",
        "103.8564831"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2939512",
      "longitude": "103.8564831",
      "address": "Nicoll Hwy, Singapore",
      "deviceId": "2023070028",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070028.jpg",
      "level": "39.94",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690965220.406,
      "modifiedDateTime": 1698583660.261,
      "formattedCreatedDateTime": "2023-8-2 16:33:40",
      "formattedModifiedDateTime": "2023-10-29 20:47:40"
    },
    {
      "id": "651bee5509eea100fccd606a",
      "coordinates": [
        "1.29895",
        "103.8583983"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.29895",
      "longitude": "103.8583983",
      "address": "177 Beach Rd, Singapore 189731",
      "deviceId": "2023070022",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070022.jpg",
      "level": "16.17",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690962832.131,
      "modifiedDateTime": 1696382557.397,
      "formattedCreatedDateTime": "2023-8-2 15:53:52",
      "formattedModifiedDateTime": "2023-10-4 09:22:37"
    },
    {
      "id": "651bee2509eea100fccd6068",
      "coordinates": [
        "1.294025",
        "103.8562417"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.294025",
      "longitude": "103.8562417",
      "address": "South Beach Avenue, 26 Beach Rd, #B1-16, Singapore 189768",
      "deviceId": "2023070041",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070041.jpg",
      "level": "66.34",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690962646.686,
      "modifiedDateTime": 1692963360.044,
      "formattedCreatedDateTime": "2023-8-2 15:50:46",
      "formattedModifiedDateTime": "2023-8-25 19:36:00"
    },
    {
      "id": "651bee0609eea100fccd6067",
      "coordinates": [
        "1.2996787",
        "103.8594886"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2996787",
      "longitude": "103.8594886",
      "address": "7500B Beach Rd, Singapore 199592",
      "deviceId": "2023070027",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070027.jpg",
      "level": "58.71",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690962373.939,
      "modifiedDateTime": 1701354535.708,
      "formattedCreatedDateTime": "2023-8-2 15:46:13",
      "formattedModifiedDateTime": "2023-11-30 22:28:55"
    },
    {
      "id": "651bedd709eea100fccd6065",
      "coordinates": [
        "1.2996746",
        "103.8602134"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2996746",
      "longitude": "103.8602134",
      "address": "253 Beach Rd, Singapore 189759",
      "deviceId": "2023070003",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070003.jpg",
      "level": "54.6",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690959192.638,
      "modifiedDateTime": 1694439820.666,
      "formattedCreatedDateTime": "2023-8-2 14:53:12",
      "formattedModifiedDateTime": "2023-9-11 21:43:40"
    },
    {
      "id": "651bedb309eea100fccd6064",
      "coordinates": [
        "1.3022852",
        "103.8641995"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3022852",
      "longitude": "103.8641995",
      "address": "700 Beach Rd, Singapore 199598",
      "deviceId": "2023070033",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070033.jpg",
      "level": "62.94",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690950434.434,
      "modifiedDateTime": 1692629787.736,
      "formattedCreatedDateTime": "2023-8-2 12:27:14",
      "formattedModifiedDateTime": "2023-8-21 22:56:27"
    },
    {
      "id": "651bed8d09eea100fccd6063",
      "coordinates": [
        "1.3067151",
        "103.8739571"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3067151",
      "longitude": "103.8739571",
      "address": "Nicoll Hwy, Singapore",
      "deviceId": "2023070002",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070002.jpg",
      "level": "63.69",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690942676.799,
      "modifiedDateTime": 1695064133.256,
      "formattedCreatedDateTime": "2023-8-2 10:17:56",
      "formattedModifiedDateTime": "2023-9-19 03:08:53"
    },
    {
      "id": "651bed6009eea100fccd6060",
      "coordinates": [
        "1.3268962",
        "103.8699186"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3268962",
      "longitude": "103.8699186",
      "address": "PIE, Singapore",
      "deviceId": "2023070019",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070019.jpg",
      "level": "74.8",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690939683.22,
      "modifiedDateTime": 1700814994.862,
      "formattedCreatedDateTime": "2023-8-2 09:28:03",
      "formattedModifiedDateTime": "2023-11-24 16:36:34"
    },
    {
      "id": "651bece409eea100fccd605c",
      "coordinates": [
        "1.3227356",
        "103.8185468"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3227356",
      "longitude": "103.8185468",
      "address": "Dunearn Rd, Lp no 98/1",
      "deviceId": "2023070026",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070026.jpg",
      "level": "7.41",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690355643.626,
      "modifiedDateTime": 1701778836.582,
      "formattedCreatedDateTime": "2023-7-26 15:14:03",
      "formattedModifiedDateTime": "2023-12-5 20:20:36"
    },
    {
      "id": "651becb809eea100fccd605a",
      "coordinates": [
        "1.3236426",
        "103.8134752"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3236426",
      "longitude": "103.8134752",
      "address": "8RF7+F9 Singapore",
      "deviceId": "2023070009",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070009.jpg",
      "level": "63.03",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690355275.143,
      "modifiedDateTime": 1695016680.725,
      "formattedCreatedDateTime": "2023-7-26 15:07:55",
      "formattedModifiedDateTime": "2023-9-18 13:58:00"
    },
    {
      "id": "651bec7509eea100fccd6058",
      "coordinates": [
        "1.3319067",
        "103.80932"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3319067",
      "longitude": "103.80932",
      "address": "140A Watten Estate Rd, Singapore 287625",
      "deviceId": "2023070023",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070023.jpg",
      "level": "44.71",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690353996.06,
      "modifiedDateTime": 1701637766.278,
      "formattedCreatedDateTime": "2023-7-26 14:46:36",
      "formattedModifiedDateTime": "2023-12-4 05:09:26"
    },
    {
      "id": "651bec5109eea100fccd6057",
      "coordinates": [
        "1.3366572",
        "103.8084586"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3366572",
      "longitude": "103.8084586",
      "address": "181 Hillcrest Rd, Singapore 289064",
      "deviceId": "2023070025",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070025.jpg",
      "level": "58.09",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690353180.781,
      "modifiedDateTime": 1700366345.701,
      "formattedCreatedDateTime": "2023-7-26 14:33:00",
      "formattedModifiedDateTime": "2023-11-19 11:59:05"
    },
    {
      "id": "651bec2709eea100fccd6055",
      "coordinates": [
        "1.316928",
        "103.8729059"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.316928",
      "longitude": "103.8729059",
      "address": "1215 Upper Boon Keng Rd, Singapore",
      "deviceId": "2023070006",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070006.jpg",
      "level": "65.63",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690345223.255,
      "modifiedDateTime": 1701609113.716,
      "formattedCreatedDateTime": "2023-7-26 12:20:23",
      "formattedModifiedDateTime": "2023-12-3 21:11:53"
    },
    {
      "id": "651bebea09eea100fccd6053",
      "coordinates": [
        "1.3109495",
        "103.8759197"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3109495",
      "longitude": "103.8759197",
      "address": "11 Lor 4 Geylang, Singapore 399268",
      "deviceId": "2023070018",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070018.jpg",
      "unit": "",
      "status": "",
      "createdDateTime": 1690275399.177,
      "modifiedDateTime": 1690275399.177,
      "formattedCreatedDateTime": "2023-7-25 16:56:39",
      "formattedModifiedDateTime": "2023-7-25 16:56:39"
    },
    {
      "id": "651bebcf09eea100fccd6052",
      "coordinates": [
        "1.3121792",
        "103.8635449"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3121792",
      "longitude": "103.8635449",
      "address": "4 Kallang Ave, #102a, Singapore 339409",
      "deviceId": "2023070031",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070031.jpg",
      "unit": "",
      "status": "",
      "createdDateTime": 1690272922.148,
      "modifiedDateTime": 1690272922.148,
      "formattedCreatedDateTime": "2023-7-25 16:15:22",
      "formattedModifiedDateTime": "2023-7-25 16:15:22"
    },
    {
      "id": "651bebae09eea100fccd6051",
      "coordinates": [
        "1.28967",
        "103.850505"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.28967",
      "longitude": "103.850505",
      "address": "Opp The Treasury, Singapore",
      "deviceId": "202307008",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/202307008.jpg",
      "unit": "",
      "status": "",
      "createdDateTime": 1690268657.846,
      "modifiedDateTime": 1690268657.846,
      "formattedCreatedDateTime": "2023-7-25 15:04:17",
      "formattedModifiedDateTime": "2023-7-25 15:04:17"
    },
    {
      "id": "651beb5c09eea100fccd604e",
      "coordinates": [
        "1.303622",
        "103.8344873"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.303622",
      "longitude": "103.8344873",
      "address": "391A Orchard Rd, Singapore 238873",
      "deviceId": "2023070061",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070061.jpg",
      "unit": "",
      "status": "",
      "createdDateTime": 1690257185.26,
      "modifiedDateTime": 1690257185.26,
      "formattedCreatedDateTime": "2023-7-25 11:53:05",
      "formattedModifiedDateTime": "2023-7-25 11:53:05"
    },
    {
      "id": "651beaf409eea100fccd604c",
      "coordinates": [
        "1.3069113",
        "103.8274653"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3069113",
      "longitude": "103.8274653",
      "address": "1 Tanglin Rd, Singapore 247905",
      "deviceId": "2023070059",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070059.jpg",
      "level": "48.63",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690256557.653,
      "modifiedDateTime": 1699540937.475,
      "formattedCreatedDateTime": "2023-7-25 11:42:37",
      "formattedModifiedDateTime": "2023-11-9 22:42:17"
    },
    {
      "id": "651beac909eea100fccd604a",
      "coordinates": [
        "1.3050412",
        "103.8255384"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3050412",
      "longitude": "103.8255384",
      "address": "3 Cuscaden Rd, Singapore 249728",
      "deviceId": "2023070043",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070043.jpg",
      "level": "33.71",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690256078.646,
      "modifiedDateTime": 1701798873.78,
      "formattedCreatedDateTime": "2023-7-25 11:34:38",
      "formattedModifiedDateTime": "2023-12-6 01:54:33"
    },
    {
      "id": "651bea9909eea100fccd6048",
      "coordinates": [
        "1.2980968",
        "103.8426119"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2980968",
      "longitude": "103.8426119",
      "address": "33 Oxley Rise, Singapore 238710",
      "deviceId": "2023070051",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070051.jpg",
      "level": "23.6",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690254108.344,
      "modifiedDateTime": 1693932553.545,
      "formattedCreatedDateTime": "2023-7-25 11:01:48",
      "formattedModifiedDateTime": "2023-9-6 00:49:13"
    },
    {
      "id": "651bea6709eea100fccd6046",
      "coordinates": [
        "1.3018457",
        "103.8527531"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3018457",
      "longitude": "103.8527531",
      "address": "175 Bencoolen St, Singapore",
      "deviceId": "2023070058",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070058.jpg",
      "level": "42.74",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690253296.89,
      "modifiedDateTime": 1693379578.484,
      "formattedCreatedDateTime": "2023-7-25 10:48:16",
      "formattedModifiedDateTime": "2023-8-30 15:12:58"
    },
    {
      "id": "651bea4209eea100fccd6045",
      "coordinates": [
        "1.3050278",
        "103.8549703"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3050278",
      "longitude": "103.8549703",
      "address": "69 Jln Besar, Singapore 208815",
      "deviceId": "2023070050",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070050.jpg",
      "level": "77.66",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690252656.877,
      "modifiedDateTime": 1701794927.733,
      "formattedCreatedDateTime": "2023-7-25 10:37:36",
      "formattedModifiedDateTime": "2023-12-6 00:48:47"
    },
    {
      "id": "651be9ff09eea100fccd6042",
      "coordinates": [
        "1.3075136",
        "103.8541878"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3075136",
      "longitude": "103.8541878",
      "address": "37 Veerasamy Rd, Singapore 207340",
      "deviceId": "2023070047",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070047.jpg",
      "level": "62.57",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690251916.954,
      "modifiedDateTime": 1701790212.89,
      "formattedCreatedDateTime": "2023-7-25 10:25:16",
      "formattedModifiedDateTime": "2023-12-5 23:30:12"
    },
    {
      "id": "651be99b09eea100fccd603f",
      "coordinates": [
        "1.3102214",
        "103.8569046"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3102214",
      "longitude": "103.8569046",
      "address": "157 Kitchener Rd, Singapore 208529",
      "deviceId": "2023070056",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070056.jpg",
      "level": "60.97",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690250950.607,
      "modifiedDateTime": 1696438784.046,
      "formattedCreatedDateTime": "2023-7-25 10:09:10",
      "formattedModifiedDateTime": "2023-10-5 00:59:44"
    },
    {
      "id": "651be94b09eea100fccd603d",
      "coordinates": [
        "1.3201467",
        "103.8699596"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3201467",
      "longitude": "103.8699596",
      "address": "69 Kallang Bahru, Singapore",
      "deviceId": "2023070044",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070044.jpg",
      "level": "65.14",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690248968.157,
      "modifiedDateTime": 1701824962.302,
      "formattedCreatedDateTime": "2023-7-25 09:36:08",
      "formattedModifiedDateTime": "2023-12-6 09:09:22"
    },
    {
      "id": "651be91009eea100fccd603b",
      "coordinates": [
        "1.3174085",
        "103.8729448"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3174085",
      "longitude": "103.8729448",
      "address": "510 Lor 3 Geylang, Singapore 388956",
      "deviceId": "2023070053",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070053.jpg",
      "level": "61.46",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690247178.068,
      "modifiedDateTime": 1700507439.885,
      "formattedCreatedDateTime": "2023-7-25 09:06:18",
      "formattedModifiedDateTime": "2023-11-21 03:10:39"
    },
    {
      "id": "651be8e509eea100fccd603a",
      "coordinates": [
        "1.316425",
        "103.8094271"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.316425",
      "longitude": "103.8094271",
      "address": "7 Gallop Walk, Singapore 258933",
      "deviceId": "2023070057",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070057.jpg",
      "level": "33.71",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690203459.619,
      "modifiedDateTime": 1694183703.081,
      "formattedCreatedDateTime": "2023-7-24 20:57:39",
      "formattedModifiedDateTime": "2023-9-8 22:35:03"
    },
    {
      "id": "651be89e09eea100fccd6037",
      "coordinates": [
        "1.309978",
        "103.8367971"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.309978",
      "longitude": "103.8367971",
      "address": "100 Anthony Rd, Singapore 229952",
      "deviceId": "2023070060",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070060.jpg",
      "level": "69.83",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690200771.134,
      "modifiedDateTime": 1699569925.089,
      "formattedCreatedDateTime": "2023-7-24 20:12:51",
      "formattedModifiedDateTime": "2023-11-10 06:45:25"
    },
    {
      "id": "651be86a09eea100fccd6036",
      "coordinates": [
        "1.309978",
        "103.8367971"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.309978",
      "longitude": "103.8367971",
      "address": "100 Anthony Rd, Singapore 229952",
      "deviceId": "2023070045",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070045.jpg",
      "level": "34.94",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1690200333.86,
      "modifiedDateTime": 1692334023.423,
      "formattedCreatedDateTime": "2023-7-24 20:05:33",
      "formattedModifiedDateTime": "2023-8-18 12:47:03"
    },
    {
      "id": "651be82c09eea100fccd6034",
      "coordinates": [
        "1.3099045",
        "103.8360581"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3099045",
      "longitude": "103.8360581",
      "address": "138 Cairnhill Rd, Singapore 229719",
      "deviceId": "2023070042",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070042.jpg",
      "level": "83.97",
      "unit": "cm",
      "status": "critical",
      "createdDateTime": 1690199733.157,
      "modifiedDateTime": 1701834089.167,
      "formattedCreatedDateTime": "2023-7-24 19:55:33",
      "formattedModifiedDateTime": "2023-12-6 11:41:29"
    },
    {
      "id": "651be7d109eea100fccd6030",
      "coordinates": [
        "1.3062897",
        "103.8032685"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3062897",
      "longitude": "103.8032685",
      "address": "29B Ridout Rd, Singapore 248425",
      "deviceId": "2023070054",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070054.jpg",
      "level": "62.31",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1690196836.19,
      "modifiedDateTime": 1701310101.966,
      "formattedCreatedDateTime": "2023-7-24 19:07:16",
      "formattedModifiedDateTime": "2023-11-30 10:08:21"
    },
    {
      "id": "651be77809eea100fccd602e",
      "coordinates": [
        "1.2957133",
        "103.8575019"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.2957133",
      "longitude": "103.8575019",
      "address": "Suntec City, Singapore",
      "deviceId": "2023070039",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070039.jpg",
      "level": "34.49",
      "unit": "cm",
      "status": "clear",
      "createdDateTime": 1688974920.982,
      "modifiedDateTime": 1698257304.058,
      "formattedCreatedDateTime": "2023-7-10 15:42:00",
      "formattedModifiedDateTime": "2023-10-26 02:08:24"
    },
    {
      "id": "651be64809eea100fccd602a",
      "coordinates": [
        "1.293322",
        "103.855504"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.293322",
      "longitude": "103.855504",
      "address": "90 Bras Basah Rd, Esplanade, Singapore 189562",
      "deviceId": "2023070036",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070036.jpg",
      "level": "78.83",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1688974847.658,
      "modifiedDateTime": 1701774792.717,
      "formattedCreatedDateTime": "2023-7-10 15:40:47",
      "formattedModifiedDateTime": "2023-12-5 19:13:12"
    },
    {
      "id": "651be5ea09eea100fccd6028",
      "coordinates": [
        "1.3278642",
        "103.8673217"
      ],
      "deviceType": "DLC",
      "binType": "",
      "projectType": "",
      "latitude": "1.3278642",
      "longitude": "103.8673217",
      "address": "12 Opal Cres, Singapore 328407",
      "deviceId": "2023070030",
      "deviceStatus": "ACTIVE",
      "image": "https://ctm-images.s3.ap-southeast-1.amazonaws.com/sensor-images/2023070030.jpg",
      "level": "51.43",
      "unit": "cm",
      "status": "moderate",
      "createdDateTime": 1688974761.522,
      "modifiedDateTime": 1701803384.397,
      "formattedCreatedDateTime": "2023-7-10 15:39:21",
      "formattedModifiedDateTime": "2023-12-6 03:09:44"
    }
  ]

  export default dicresponse;