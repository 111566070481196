import { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

Auth.configure(awsconfig);

const login = (data) => {
    return Auth.signIn({
        username: data.email,
        password: data.password
    }).then((response) => {
        console.log(JSON.stringify(response));
        if(response !== null) {
            localStorage.clear();
            localStorage.setItem('user',JSON.stringify(response));
            console.log('localStorage set');
        }
        return response;
    })
}

const isAuthenticated = () => {
    const authenticated = true; //TOBE CHANGED
    return Auth.currentAuthenticatedUser()
        .then((resp) => {
            console.log('[Success]Current Authenticated User: '+JSON.stringify(resp));
            authenticated = true;
            return authenticated;
        }).catch((err) => {
            console.log('[Error]Current Authenticated User: '+JSON.stringify(err));
            return authenticated;
        })
}

const register = (data) => {
    return Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
            email: data.email,
            name: data.name
        }
    })
}

const forgotPassword = (data) => {
    return Auth.forgotPassword({
        username: data.email
    })
}

const logout = () => {
    localStorage.removeItem("user");
    Auth.currentUserInfo().then((user) => {
        console.log('User is successfully signout',JSON.stringify(user));
        //user.signOut();
    })
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    login,
    register,
    forgotPassword,
    logout,
    getCurrentUser,
    isAuthenticated
};