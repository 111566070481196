

const UrlConfigs = {
    MAP_STYLE_URL : 'mapbox://styles/mapbox/streets-v11',
    MAPBOX_ACCESS_TOKEN : 'pk.eyJ1Ijoic3JpbmF0aHJhamEiLCJhIjoiY2xoNXR2NGp2MGJzMDNlczg5ZDA5ZTNwdSJ9.VPL2vn4eXArjXHpPz9RYQg',
    API_URL: 'https://api.pixvisonz.com/v1/'
}

export default UrlConfigs

//'mapbox://styles/mapbox/streets-v11',
//mapbox://styles/mapbox/outdoors-v11
//mapbox://styles/mapbox/light-v10
//mapbox://styles/mapbox/dark-v10
//mapbox://styles/mapbox/satellite-v9
//mapbox://styles/mapbox/satellite-streets-v11
//mapbox://styles/mapbox/traffic-day-v2
//mapbox://styles/mapbox/traffic-night-v2
//mapbox://styles/mapbox/streets-basic-v11  ==
//mapbox://styles/mapbox/streets-satellite-v11
//mapbox://styles/mapbox/streets-terrain-v11 ==
//mapbox://styles/mapbox/streets-traffic-v11
//mapbox://styles/mapbox/streets-night-v11
//mapbox://styles/mapbox/comic-v9
//mapbox://styles/mapbox/emerald-v8
//mapbox://styles/mapbox/high-contrast-v10 //
//mapbox://styles/mapbox/wheatpaste-v8
//mapbox://styles/mapbox/pirates-v11
//mapbox://styles/mapbox/pencil-v9
//mapbox://styles/mapbox/satellite-streets-basic-v8
