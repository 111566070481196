import React, { useState, useCallback, Fragment, useEffect, lazy, Suspense } from "react";
import { Grid, Card, CardContent, Typography, TextField } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import DataServices from "../../Services/DataServices";
import CardHeader from '@mui/material/CardHeader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuList from "../../Constants/list";
import Loading from "../../Controller/LoadingController/Loading";
import { colors } from "@mui/material";
import Box from "@mui/material";

import CardCustomHeader from '../../Controller/HeaderController/CardCustomHeader'
import Header from '../../Controller/HeaderController/Header'
import SeverityBasedView from "./SeverityBasedView";
import { StreetDataTable } from "./StreetDatatable";
import { LapsesChart } from "./LapsesChart";
import { AssetsChart } from "./AssertsChart";
import { RoutesChart } from "./RoutesChart";
//import ComponentOne from "./ComponentOne"

// const CardCustomHeader = React.lazy(() => import('../../Controller/HeaderController/CardCustomHeader'));
// const Header = React.lazy(() => import('../../Controller/HeaderController/Header'));
// const SeverityBasedView = React.lazy(() => import('./SeverityBasedView'));
// const StreetDataTable = React.lazy(() => import('./StreetDatatable'));
// const LapsesChart = React.lazy(() => import('./LapsesChart'));
// const AssetsChart = React.lazy(() => import('./AssertsChart'));
// const RoutesChart = React.lazy(() => import('./RoutesChart'));
const ComponentOne = React.lazy(() => import('./ComponentOne'));

const OneMapComponent = React.lazy(() => import('./MapView/OneMap'));


const cardContentStyle = {
 paddingTop: `2px`, paddingBottom:5, paddingLeft: `2px`, paddingRight: `2px`
}

function HomeComponent(){
    const theme = useTheme();
    const [severityPercent, setSeverityPercent] = React.useState({});
    const [sectorChart, setSectorChart] = React.useState({});
    const [criticalCount, setCriticalCount] = React.useState(0);
    const [sensorDevices, setSensorDevices] = React.useState([]);
    const [sensorCriticalCount, setSensorCriticalCount] = React.useState(0);
    const [selectODC, setSelectODC] = useState('odcai_track2');


    const gridStyleForCard = {
        backgroundColor: '#4B5563',
        textAlign: `left`,
        color: theme.palette.warning.main,
        fontSize: `7px`,
        paddingLeft: `5px`
    }

    const handleODCChange=(event)=>{
        setSelectODC(event.target.value);
      }


    useEffect(() => {
        retrieveData();
      },[])

      useEffect(() => {
        console.log('sData.length',JSON.stringify(sensorDevices));
        if(sensorDevices!=null && sensorDevices.length > 0) {
            let sData = sensorDevices.filter(sc => sc.level>80);
            console.log('sData.length',JSON.stringify(sData));
            setSensorCriticalCount(sData.length);
        }
      },[sensorDevices])

    const setData = (data) => {
        setSeverityPercent({critical: data.criticalPercentage, moderate: data.moderatePercentage, clean: data.cleanPercentage})
        setCriticalCount(data.criticalCount);
        setSectorChart(data.severityChart);
    }

    const retrieveData = async() => {
        await DataServices.getSectorSeverity()
          .then((response) => {
           // console.log('response from getSectorSeverity: '+JSON.stringify(response.data.data));
            setData(response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };

    return(
        <Grid container spacing={0} style={{marginTop: `-20px`}}>
            <Grid item xs={12} md={12} sm={12} >
                <Header title={`On Demand Cleaning Optimization`} />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <SeverityBasedView/>
            </Grid>
            <Grid item xs={12} md={12} sm={12} >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9.5} sm={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} sm={12}>
                            <Suspense fallback={
                            <div>
                                <Loading loading={true} type={`dots`} backDrop={false}/>
                            </div>
                            }>
                                <OneMapComponent odcValue={selectODC} count={e=> setSensorDevices(e)}/>
                            </Suspense>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <Card > 
                                <RoutesChart />
                            </Card>  
                        </Grid>
                    </Grid>
                       
                    </Grid>
                    <Grid item xs={12} md={2.5} sm={2}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12} md={12} sm={12} >
                            <TextField     
                               sx={{m: 1}}                    
                               labelId="demo-simple-select-helper-label"
                               id="demo-simple-select-helper"
                               value={selectODC}
                               label="Select the vehicle"
                               onChange={handleODCChange}
                               size="small"
                               select
                               SelectProps={{ IconComponent: () => null }}
                               fullWidth
                                >

                                {MenuList.length > 0 && MenuList.map((x, i)=>{
                                    return(
                                        <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                        </MenuItem>
                                    )               
                                })}
                            </TextField>
                                            
                            </Grid>      
                            <Grid item xs={12} md={12} sm={12} >
                                <Card>
                                    <CardContent style={cardContentStyle}>     
                                        {/* <CardCustomHeader title={`STREETS`} varient={'body2'} alignment={`center`}/>      */}
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} style={gridStyleForCard}>
                                                <p>STREETS</p>   
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <StreetDataTable severityPercent={severityPercent}
                                                sectorChart={sectorChart} criticalCount={criticalCount}/>
                                            </Grid>
                                        </Grid>    
                                    </CardContent>
                                </Card>                                
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                                <Card>
                                    <CardContent style={cardContentStyle}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} style={gridStyleForCard}>
                                                <p>{selectODC == 'dicSensor' ? 'DIC SENSOR FEEDBACK'  : 
                                                selectODC == 'smartCombi' ? 'SMART COMBI ARM FEEDBACK' : 'ON-DEMAND CLEANING FEEDBACK'

                                                }
                                                    </p>   
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Card style={{backgroundColor: theme.palette.info.main, margin: 4}}>
                                                    <Typography variant="h4" style={{ padding: "10px", textAlign:`center`}}>
                                                    {selectODC == 'dicSensor' ? `${sensorCriticalCount}` : selectODC == 'smartCombi' ?  `${'300/1000'}` :  `${criticalCount}`}
                                                    </Typography>
                                                </Card>   
                                            </Grid>
                                        </Grid>                           
                                    </CardContent>
                                </Card>
                           
                                
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                                <Card>
                                    <CardContent  style={cardContentStyle}>  
                                       {/*  <CardCustomHeader title={`ASSETS`} varient={'body2'} alignment={`center`}/>  */}    
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} style={gridStyleForCard}>
                                                <p>ASSETS</p>   
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <AssetsChart />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>  
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                                <Card>
                                    <CardContent style={cardContentStyle}>  
                                        {/* <CardCustomHeader title={`LAPSES`} varient={'body2'} alignment={`center`}/> */}  
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} style={gridStyleForCard}>
                                                <p>LAPSES</p>   
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <LapsesChart />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card> 
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid> 
                      
        </Grid>         
    );
}

export default HomeComponent


/*
<Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={3}>
                        <Card>
                        
                            <CardContent>  
                                <CardCustomHeader title={`ASSETS`} />                         
                                <AssetsChart />
                            </CardContent>
                        </Card>                        
                    </Grid>
                    <Grid item xs={12} md={3} sm={3}>
                        <Card>
                            <CardCustomHeader title={`LAPSES`} />     
                            <CardContent>  
                                
                                <LapsesChart />
                            </CardContent>
                        </Card>  
                        
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <Card>
                        <CardCustomHeader title={`ROUTES`} />
                            <CardContent>  
                                
                            <RoutesChart />
                            </CardContent>
                        </Card>  
                    </Grid>
                </Grid>
            </Grid> 
*/