import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Logo from '../../Assets/ALAI_logo.png'

export default function Header(props) {

    return(
        <Grid container >
            <Grid item xs={6}>
                <Typography variant="h5">{props.title}</Typography>
            </Grid>
        </Grid>
    );
}