import React, { useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import DataServices from '../../Services/DataServices';
import ChartWebSocket from '../../WebSockets/ChartWebSocket';

export const RoutesChart = () => {
    const [response, setResponse] = React.useState([]);
    let onWsMessageReceived = (msg) => {
      setResponse(msg.routesSeverity);
    }
    const retrieveData = () => {
        DataServices.getRouteSeverity()
        .then((response) => {
          //console.log('response from getRouteSeverity: '+JSON.stringify(response.data.data));
          setResponse(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    useEffect(() => {
      retrieveData()
    }, [])
    return (
        <div style={{ height: 120, margin: `0px` }}>
            <p style={{ fontSize: `10px`, textAlign: `center`, margin: `0px`}}>ROUTES</p>
            <ResponsiveBar
                data={response}
                keys={[ 'critical', 'moderate', 'clean' ]}
                indexBy="name"
                margin={{ top: 0, right: 50, bottom: 50, left: 50 }}
                layout="vertical"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#e25d47','#f4ca60','#97e3d5']}
                //padding={"0.5"}
                //innerPadding={"5px"}
                enableLabel={false}
                theme={{
                  textColor: 'white',
                  fontSize: `7px`,
                  tooltip: {
                    container: {
                      color: 'black',
                      background: 'white',
                    },
                  },
                }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                axisBottom={{
                    tickSize: 2,
                    tickPadding: 3,
                    tickRotation: -25,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 10
                }}
                labelSkipWidth={0}
                labelSkipHeight={2}
                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableGridX={false}
                enableGridY={false}
            />
        <ChartWebSocket onChange={onWsMessageReceived} />
        </div>
    )
}
